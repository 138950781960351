import styled from 'styled-components';
import { white } from '../../../../constants/theme';

export const HomePresentation = styled.div`
  margin-top: 2rem;
  margin-left: 11.5rem;
`;
export const TextBlock = styled.div`
  display: flex;
`;

export const TextCta = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 1.8rem;
  line-height: 2.5rem;
  width: 50rem;
  height: 34rem;
  background-color: ${white};
  padding: 1.4rem;
  margin-right: 2rem;
`;

export const Text = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 2.2rem;
  line-height: 2.5rem;
  max-width: 35rem;
`;

export const Arrow = styled.div`
  display: block;
  margin: 6px;
`;

export const Video = styled.div`
  display: block;
  background-color: ${white};
  padding: 1.4rem;
  height: 34rem;
`;